import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import testimonials1 from "../../assets/img/testimonial/t7.JPG";
import testimonials2 from "../../assets/img/testimonial/t6.JPG";
import testimonials3 from "../../assets/img/testimonial/t9.JPG";
import testimonials4 from "../../assets/img/testimonial/t8.JPG";
// import testimonials4 from "../../assets/img/testimonials/testimonials-4.jpg";

// import testimonials5 from "../../assets/img/testimonials/testimonials-5.jpg";
import SectionHeader from "../../shared/SectionHeader";


const properties = {
    duration: 7000,
    transitionDuration: 700,
    infinite: true,
    indicators: false,
    arrows: false,
    pauseOnHover: false,
    slidesToScroll: 1,
    slidesToShow: 2
};

const responsiveSettings = [
    {
        breakpoint: 1000,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    },
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    },
    {
        breakpoint: 600,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    },
    {
        breakpoint: 400,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 200,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
];

function TestimonialSection() {

    return (
        // <!-- ======= Testimonials Section ======= -->
        <section id="testimonials" className="testimonials section-gap">
            <div className="container">

                <div className="row d-flex justify-content-center">
                    <div className="col-lg-6">
                        <SectionHeader
                            title="Testimonials"
                            description="Plex parking would be noting without its clients. Here are our clients responses to the services we have offered to the public thus far"
                        />
                    </div>
                </div>

                <div className="slides-2 swiper">
                    <div className="testimonal-slide-wrapper">
                        <Slide
                            easing="ease"
                            {...properties}
                            responsive={responsiveSettings}
                        >
                            <div className="swiper-slide">
                                <div className="testimonial-wrap">
                                    <div className="testimonial-item">
                                        <img src={testimonials1} className="testimonial-img" alt="" />
                                        <h3>Andrew Sekirime</h3>
                                        <h4>Thrivetec team lead</h4>
                                        <div className="stars">
                                            {/* <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i> */}

                                            <i className='bx bxs-star'></i><i className='bx bxs-star'></i><i className='bx bxs-star'></i><i className='bx bxs-star'></i><i className='bx bx-star' ></i>

                                        </div>
                                        <p>
                                            {/* <i className="bi bi-quote quote-icon-left"></i> */}
                                            <i className='bx bxs-quote-left'></i>
                                            As time and time has passed, the parking service has greatly improved and I believe a lot of clients including governement have benefitted greatly. Thanks to this amazing app...
                                            {/* <i className="bi bi-quote quote-icon-right"></i> */}
                                            <i className='bx bxs-quote-right'></i>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="testimonial-wrap">
                                    <div className="testimonial-item">
                                        <img src={testimonials2} className="testimonial-img" alt="" />
                                        <h3>Abdallah Wanjusi</h3>
                                        <h4>Android Enthusian</h4>
                                        <div className="stars">
                                            {/* <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i> */}

                                            <i className='bx bxs-star'></i><i className='bx bxs-star'></i><i className='bx bxs-star'></i><i className='bx bxs-star'></i><i className='bx bxs-star'></i>
                                        </div>
                                        <p>
                                            {/* <i className="bi bi-quote quote-icon-left"></i> */}
                                            <i className='bx bxs-quote-left'></i>
                                            My parking sport is always good to go thanks to the app. I even don't have issues making payment too. I just use the app.
                                            {/* <i className="bi bi-quote quote-icon-right"></i> */}
                                            <i className='bx bxs-quote-right'></i>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="testimonial-wrap">
                                    <div className="testimonial-item">
                                        <img src={testimonials3} className="testimonial-img" alt="" />
                                        <h3>Naome Kizza</h3>
                                        <h4>Full stack developer</h4>
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>

                                            <i className='bx bxs-star'></i><i className='bx bxs-star'></i><i className='bx bxs-star'></i><i className='bx bxs-star'></i><i className='bx bxs-star'></i>
                                        </div>
                                        <p>
                                            {/* <i className="bi bi-quote quote-icon-left"></i> */}
                                            <i className='bx bxs-quote-left'></i>
                                            When I parked in town, I was really welcomed so well by the multiplex workers and everything went smootly right from parking to even making payment. Good Job
                                            {/* <i className="bi bi-quote quote-icon-right"></i> */}
                                            <i className='bx bxs-quote-right'></i>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="swiper-slide">
                                <div className="testimonial-wrap">
                                    <div className="testimonial-item">
                                        <img src={testimonials4} className="testimonial-img" alt="" />
                                        <h3>Douglas Kato</h3>
                                        <h4>Full stack developer</h4>
                                        <div className="stars">
                                            {/* <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i> */}

                                            <i className='bx bxs-star'></i><i className='bx bxs-star'></i><i className='bx bxs-star'></i><i className='bx bx-star' ></i><i className='bx bx-star' ></i>
                                        </div>
                                        <p>
                                            {/* <i className="bi bi-quote quote-icon-left"></i> */}
                                            <i className='bx bxs-quote-left'></i>
                                            I can now easily find parking ithout roating around town becaue of the help of this application... how great is that
                                            {/* <i className="bi bi-quote quote-icon-right"></i> */}
                                            <i className='bx bxs-quote-right'></i>
                                        </p>
                                    </div>
                                </div>
                            </div>

                            {/* <div className="swiper-slide">
                                <div className="testimonial-wrap">
                                    <div className="testimonial-item">
                                        <img src={testimonials4} className="testimonial-img" alt="" />
                                        <h3>Matt Brandon</h3>
                                        <h4>Freelancer</h4>
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                        </div>
                                        <p>
                                            <i className="bi bi-quote quote-icon-left"></i>
                                            Fugiat enim eram quae cillum dolore dolor amet nulla culpa multos export minim fugiat minim velit
                                            minim dolor enim duis veniam ipsum anim magna sunt elit fore quem dolore labore illum veniam.
                                            <i className="bi bi-quote quote-icon-right"></i>
                                        </p>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="swiper-slide">
                                <div className="testimonial-wrap">
                                    <div className="testimonial-item">
                                        <img src={testimonials5} className="testimonial-img" alt="" />
                                        <h3>John Larson</h3>
                                        <h4>Entrepreneur</h4>
                                        <div className="stars">
                                            <i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i><i
                                                className="bi bi-star-fill"></i><i className="bi bi-star-fill"></i>
                                        </div>
                                        <p>
                                            <i className="bi bi-quote quote-icon-left"></i>
                                            Quis quorum aliqua sint quem legam fore sunt eram irure aliqua veniam tempor noster veniam enim
                                            culpa labore duis sunt culpa nulla illum cillum fugiat legam esse veniam culpa fore nisi cillum
                                            quid.
                                            <i className="bi bi-quote quote-icon-right"></i>
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                        </Slide>
                    </div>
                </div>

            </div>
        </section>
    );

}

export default TestimonialSection;
