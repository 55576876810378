import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/img/plex/logo.png";


const Footer = () => {

    const plex = () => {
        window.open("https://play.google.com/store/apps/details?id=com.multiplex.parking");
    }

    // const thrivetec = () => {
    //     window.open("https://thrivetecug.netlify.app/");
    // }

    const multiplex = () => {
        window.open("https://multiplexstreetparking.com/");
    }

    const email = () => {
        window.open("mailto:thrivetecug7@gmail.com");
    }

    const currentday = new Date();

    const [experience] = useState(currentday.getFullYear())

    const [activeLocationPath] = useState(window.location.pathname);
    const [activeRoot, setActiveRoot] = useState(true);

    useEffect(() => {
        const pathSections = activeLocationPath.split("/");
        setActiveRoot("/" + pathSections[1]);
    }, [activeLocationPath]);

    return (
        <footer className="footer-area">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6 single-footer-widget">
                        <div id="logo" className='mb-4'>
                            <Link to="/">
                                <img src={logo} alt="" title="" style={{ height: "42px" }} />
                            </Link>
                        </div>
                        <p>Plex parking is a parking application developed for the ease of parking on the kampala streets for the clients. It is both at Play store and Apple store for clients to download and utilize to help them find parking and make easy payment in the system.</p>

                    </div>

                    <div className="col-lg-4 col-md-6 single-footer-widget text-center">
                        <h4>Useful Links</h4>
                        <ul>
                            <li className={`nav-item ${(activeRoot === '/') && 'menu-active'}`}>
                                <Link to="/" onClick={() => window.scrollTo(0, 0)}>Home</Link>
                            </li>
                            <li className={`nav-item ${(activeRoot === '/about') && 'menu-active'}`}>
                                <Link to="/about" onClick={() => window.scrollTo(0, 0)}>About</Link>
                            </li>
                            <li className={`nav-item ${(activeRoot === '/pricing') && 'menu-active'}`}>
                                <Link to="/pricing" onClick={() => window.scrollTo(0, 0)}>Pricing</Link>
                            </li>
                            <li className={`nav-item ${(activeRoot === '/contact') && 'menu-active'}`}>
                                <Link to="/contact" onClick={() => window.scrollTo(0, 0)}>Contact</Link>
                            </li>
                            <li className={`nav-item ${(activeRoot === '/') && 'menu-active'}`}>
                                <Link onClick={multiplex} to="#">Multiplex Street Parking</Link>
                            </li>
                        </ul>
                    </div>

                    <div className="col-lg-4 col-md-6 single-footer-widget">
                        <h4>Get in touch</h4>
                        <p>You can trust us. If you have any issues you would like to raise about the application, please do not hesitate to reach out to us right here.</p>
                        <div className="form-wrap" id="mc_embed_signup">
                            <Link className="primary-btn text-uppercase" onClick={email} to="#">Send Email</Link>

                            {/* <form target="_blank"
                                action="https://spondonit.us12.list-manage.com/subscribe/post?u=1462626880ade1ac87bd9c93a&amp;id=92a4423d01"
                                method="get" className="form-inline">
                                <input className="form-control" name="EMAIL" placeholder="Your Email Address"
                                    onfocus="this.placeholder = ''" onblur="this.placeholder = 'Your Email Address '"
                                    required="" type="email" />
                                <button className="click-btn btn btn-default"><span className="lnr lnr-arrow-right"></span></button>
                                <div style={{ position: 'absolute', left: "-5000px" }}>
                                    <input name="b_36c4fd991d266f23781ded980_aefe40901a" tabindex="-1" value="" type="text" />
                                </div>

                                <div className="info"></div>
                            </form> */}
                        </div>
                    </div>
                </div>
                <div className="footer-bottom row align-items-center">
                    <p className="footer-text m-0 col-lg-6 col-md-12">
                        Copyright &copy;{experience} All rights reserved | <Link to="#">PLEX</Link>
                    </p>
                    <div className="col-lg-6 col-md-6 social-link">
                        <div className="download-button d-flex flex-row justify-content-end">
                            <div className="buttons gray flex-row d-flex">
                                <i className="fa fa-apple" aria-hidden="true"></i>
                                <div className="desc">
                                    <Link onClick={plex} to="#">
                                        <p>
                                            <span>Available</span> <br />
                                            on App Store
                                        </p>
                                    </Link>
                                </div>
                            </div>
                            <div className="buttons gray flex-row d-flex">
                                <i className="fa fa-android" aria-hidden="true"></i>
                                <div className="desc">
                                    <Link onClick={plex} to="#">
                                        <p>
                                            <span>Available</span> <br />
                                            on Play Store
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default Footer;
