import React from "react";


function SectionHeader({ title, description, margin }) {

    return (

        <div className={`section-title text-center ${margin}`}>
            <h2>{title}</h2>
            <p>{description}</p>
        </div>

    );

}

export default SectionHeader;
