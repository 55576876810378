import React from "react";
import SectionHeader from "../../shared/SectionHeader";
import MapSection from "./MapSection";
import BranchesSection from "./BranchesSection";

function ContactSection() {
    return (

        <section className="contact-page-area section-gap-top">
            <div className="container">
                <MapSection />

                <div className="row d-flex justify-content-center section-gap">
                    <div className="col-lg-6">
                        <SectionHeader
                            title="Contact"
                            description="We would like you as our customer or client to contact us incase of any inquires or issues that you would like to address to us. Below are our contacts"
                        />
                    </div>
                </div>

                <div className="row justify-content-center">

                    <div className="col-xl-4 col-lg-4" >
                        <div className="info-box mb-4">
                            <i className="bx bxs-map"></i>
                            <h3>Our Address</h3>
                            <p>
                                8H6M+G6F, Russel Ln, <br />
                                P.O. Box 3874 Nakasero Rd
                            </p>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-4"  >
                        <div className="info-box mb-4">
                            <i className="bx bxs-envelope"></i>
                            <h3>Email Us</h3>
                            <p>
                                admin@Multiplexug.com <br />
                                multiplexug@gmail.com
                            </p>
                        </div>
                    </div>

                    <div className="col-xl-4 col-lg-4"   >
                        <div className="info-box mb-4">
                            <i className="bx bxs-phone-call"></i>
                            <h3>Call Us</h3>
                            <p>
                                +256 312 360100 <br />
                                +256 393 202703
                            </p>
                        </div>
                    </div>

                </div>

                <BranchesSection />
            </div>
        </section>

    );
}

export default ContactSection;
