import React from "react";
import SectionHeader from "../../shared/SectionHeader";

function PricingSection() {

    return (
        <section className="pricing-area pricing-page">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-6">
                        <SectionHeader
                            title="Parking Pricing"
                            description="Below is our pricing criteria for parking locations, slots and our reservation areas around the streets of Kampala and any other Multiplex operated area."
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-price">
                            <div className="top-sec d-flex justify-content-between">
                                <div className="top-left">
                                    <h4>Parking</h4>
                                    <p>Normal <br />vehicles</p>
                                </div>
                                <div className="top-right">
                                    <div className="d-flex align-items-center">
                                        <sup>Shs</sup><h1>1,000</h1><span>/hr</span>
                                    </div>
                                </div>
                            </div>
                            <div className="end-sec">
                                <ul>
                                    <li className="d-flex align-items-center"><i className="bx bx-check-circle"></i> <span>Pay Shs 800 after 2hr parking</span></li>
                                    <li className="d-flex align-items-center"><i className="bx bx-check-circle"></i> <span>Request to end parking</span></li>
                                    <li className="d-flex align-items-center"><i className="bx bx-check-circle"></i> <span>Notified when parking</span></li>
                                    <li className="d-flex align-items-center"><i className="bx bx-check-circle"></i> <span>Pay with various methods</span></li>
                                </ul>
                                {/* <button className="primary-btn price-btn mt-20">CHECK IT OUT</button> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-price">
                            <div className="top-sec d-flex justify-content-between" style={{ backgroundColor: "#ff4800", color: "#fff", borderRadius: "10px 10px 0 0" }}>
                                <div className="top-left">
                                    <h4>Stickers</h4>
                                    <p>Normal <br />vehicles</p>
                                </div>
                                <div className="top-right">
                                    <div className="d-flex align-items-center">
                                        <sup style={{ color: "#fff" }}>Shs</sup><h1>55,000</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="end-sec">
                                <ul>
                                    <li className="d-flex align-items-center"><i className="bx bx-check-circle"></i> <span>Payable with various methods</span></li>
                                    <li className="d-flex align-items-center"><i className="bx bx-check-circle"></i> <span>Duration date is 2 weeks</span></li>
                                    <li className="d-flex align-items-center"><i className="bx bx-check-circle"></i> <span>Applicable on all types of cars</span></li>
                                    <li className="d-flex align-items-center"><i className="bx bx-check-circle"></i> <span>Request to end parking</span></li>
                                </ul>
                                {/* <button className="primary-btn price-btn mt-20">CHECK IT OUT</button> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-price">
                            <div className="top-sec d-flex justify-content-between">
                                <div className="top-left">
                                    <h4>Reserve</h4>
                                    <p>Companies</p>
                                </div>
                                <div className="top-right">
                                    <div className="d-flex align-items-center">
                                        <sup>Shs</sup><h1>300,000</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="end-sec">
                                <ul>
                                    <li className="d-flex align-items-center"><i className="bx bx-check-circle"></i> <span>Wide space variety provided</span></li>
                                    <li className="d-flex align-items-center"><i className="bx bx-check-circle"></i> <span>Private and Governement access</span></li>
                                    <li className="d-flex align-items-center"><i className="bx bx-check-circle"></i> <span>Payable with various methods</span></li>
                                    <li className="d-flex align-items-center"><i className="bx bx-check-circle"></i> <span>Batch payments possible</span></li>
                                </ul>
                                {/* <button className="primary-btn price-btn mt-20">CHECK IT OUT</button> */}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    );

}

export default PricingSection;
