import React from "react";
import { Link } from "react-router-dom";

import plexgif from "../../assets/img/plex/plexapp.gif";


function AppSection() {

    return (

        <div className="row align-items-center">
            <div className="col-lg-5 home-about-left d-flex justify-content-center" style={{ marginLeft: "55px", }}>

                <img
                    className="img-fluid"
                    style={{
                        width: "350px",
                        margin: "35px 0 35px 0",
                    }}
                    src={plexgif}
                    alt=""
                />

            </div>

            <div className="offset-lg-1 col-lg-5 home-about-right">
                <h1>
                    Plex Parking, the <br/>best suited solution for your parking
                </h1>
                <p>
                    You can now easily pay for parking quickly, easily find available parking slot and
                    monitor your parking activity to avoid being clamped by using the Plex application,
                    Plex Parking is available on both Google Play Store & App Store.
                </p>
                <div className="download-button d-flex flex-row justify-content-start">
                    <Link className="primary-btn text-uppercase mt-3" onClick={() => window.scrollTo(0, 0)} to="/about">Get Details</Link>
                </div>
            </div>
        </div>

    );

}

export default AppSection;
