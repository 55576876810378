import React from 'react';
import SectionHeader from '../../shared/SectionHeader';


function BranchesSection() {

    return (
        <>
            <div className="row d-flex justify-content-center section-gap">
                <div className="col-lg-6">
                    <SectionHeader
                        title="Branches"
                        // margin="mt-5"
                        description="These are our stationed branches where you can make payment or even inquire about certain information you might need to know from Multiplex Street parking..."
                    />
                </div>
            </div>

            <div className="row justify-content-center">

                <div className=" col-xl-4 col-lg-4">
                    <div className="info-box mb-4">
                        <i className="bx bxs-map"></i>
                        <h3>Branch "A"</h3>
                        <p>
                            at Master plaza, 1st Floor, Room F02, Next to Equatorial Building, Ben Kiwanuka.
                        </p>
                    </div>
                </div>

                <div className=" col-xl-4 col-lg-4">
                    <div className="info-box mb-4">
                        <i className="bx bxs-map"></i>
                        <h3>Branch "B"</h3>
                        <p>
                            at Jumbo Plaza, Ground floor, shop No. 1
                            along Parliament avenue
                        </p>
                    </div>
                </div>

                <div className=" col-xl-4 col-lg-4">
                    <div className="info-box mb-4">
                        <i className="bx bxs-map"></i>
                        <h3>Branch "C"</h3>
                        <p>
                            at Fortune Paza, 1st floor, Shop No. FF11
                            Wilson Street around Arua Park
                        </p>
                    </div>
                </div>

                <div className=" col-xl-4 col-lg-4">
                    <div className="info-box mb-4">
                        <i className="bx bxs-map"></i>
                        <h3>Branch "D"</h3>
                        <p>
                            at Energy Centre Building, Ground Floor,
                            Room D70 along Market Street
                        </p>
                    </div>
                </div>

                <div className=" col-xl-4 col-lg-4">
                    <div className="info-box mb-4">
                        <i className="bx bxs-map"></i>
                        <h3>Branch "E"</h3>
                        <p>
                            at Securex (SAL Building), Ground floor, Room U031 along
                            Nkurumah Road
                        </p>
                    </div>
                </div>

                <div className=" col-xl-4 col-lg-4">
                    <div className="info-box mb-4">
                        <i className="bx bxs-map"></i>
                        <h3>Branch "P"</h3>
                        <p>
                            General Post Office Building, Ground floor, Counter No.7 along Kampala road
                        </p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default BranchesSection;
