import React from "react";
import SectionHeader from "../../shared/SectionHeader";

function MapSection() {
  return (
    <>
      <div className="row d-flex justify-content-center">
        <div className="col-lg-6">
          <SectionHeader
            title="Location"
            description="This is our location on the map... You are free to come to our offices for any inquiries or issues at hand to be addressed."
          />
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="col-xl-12 col-lg-12">
          <iframe
            title="map"
            frameborder="0"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15959.037717645646!2d32.5830574!3d0.3113048!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x63e22dd66ef4c222!2sMultiplex%20Ltd!5e0!3m2!1sen!2sug!4v1656961028466!5m2!1sen!2sug"
            width="100%"
            height="450"
            style={{ border: "0" }}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          >

          </iframe>
        </div>
      </div>
    </>
  )
}

export default MapSection;
