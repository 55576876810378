
import React from "react";
import { Link } from "react-router-dom";
import SectionHeader from "../../shared/SectionHeader";



function FeaturesSection() {

    return (

        <section className="feature-area section-gap-top">
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-6">
                        <SectionHeader
                            title="Unique Features"
                            description="Plex parking is bringing so many unique features to its clients that they can start utilizing. A few most important when parking are:"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="single-feature">
                            <Link to="#" className="title plex-cursor">
                                {/* <!-- <span className="lnr lnr-book"></span> --> */}
                                <i className="bx bxs-car"></i>
                                <h3>Find a parking spot</h3>
                            </Link>
                            <p>
                                Easily find your parking spot in Kampala city with just the app and worry no more about
                                where to park.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-feature">
                            <Link to="#" className="title plex-cursor">
                                {/* <!-- <span className="lnr lnr-book"></span> --> */}
                                <i className='bx bxs-parking'></i>
                                <h3>Request to unpark</h3>
                            </Link>
                            <p>
                                You can now easily request to get out of parking if personel is no where to be seen. Just
                                a tap and request.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single-feature">
                            <Link to="#" className="title plex-cursor">
                                {/* <!-- <span className="lnr lnr-book"></span> --> */}
                                <i className='bx bxs-credit-card'></i>
                                <h3>Easily pay</h3>
                            </Link>
                            <p>
                                With a variety of payment modes including virtual tickets and so much more, you can now
                                easily pay.
                            </p>
                        </div>
                    </div>

                </div>
            </div>
        </section>

    );

}

export default FeaturesSection;
