import React from 'react'
import Header from '../../shared/Header';
import Footer from '../../shared/Footer';
import ContactSection from '../../components/contact/ContactSection';

function ContactPage(){
    return (
        <div>
            <Header/>
            <ContactSection/>
            <Footer/>
        </div>
    );
}

export default ContactPage;
