
import React from "react";
import { Link } from "react-router-dom";
import { Fade } from "react-slideshow-image";

import slide1 from "../../assets/img/sliders/banner_1.png";
import slide2 from "../../assets/img/sliders/banner_2.svg";
import slide3 from "../../assets/img/sliders/banner_3.png";

import "react-slideshow-image/dist/styles.css";


const properties = {
    duration: 7000,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: false,
    pauseOnHover: false
};

function BannerSection() {

    const plex = () => {
        window.open("https://play.google.com/store/apps/details?id=com.multiplex.parking");
    }

    return (

        <div className="home-banner">

            <div className="home-banner-slide">
                <Fade easing="ease" {...properties}>
                    <div className="home-banner-area" style={{ backgroundImage: `url(${slide1})` }}></div>

                    <div className="home-banner-area" style={{ backgroundImage: `url(${slide2})` }}></div>

                    <div className="home-banner-area" style={{ backgroundImage: `url(${slide3})` }}></div>

                    {/* <div className="home-banner-area" style={{ backgroundImage: `url(${slide4})` }}></div> */}
                </Fade>
            </div>

            <section className="home-banner-content">
                <div className="container">
                    <div className="row fullscreen d-flex align-items-center justify-content-between ">
                        <div className="banner-img col-lg-7 col-md-7">
                            {/* <img className="img-fluid" src={phone} alt="" /> */}
                        </div>

                        <div className=" col-lg-5 col-md-5">
                            <h1>
                                Park, Pay <br /> Be on your way
                            </h1>
                            <p>
                                A more efficient and effective parking experience in your hand given to you on the streets of Kampala city. Download the app
                                today and access great features.
                            </p>
                            <div className="download-button d-flex flex-row justify-content-start">
                                <div className="buttons flex-row d-flex">
                                    <i className="fa fa-apple" aria-hidden="true"></i>
                                    <div className="desc">
                                        <Link onClick={plex} to="#">
                                            <p>
                                                <span>Available</span> <br />
                                                on App Store
                                            </p>
                                        </Link>
                                    </div>
                                </div>

                                <div className="buttons dark flex-row d-flex">
                                    <i className="fa fa-android" aria-hidden="true"></i>
                                    <div className="desc">
                                        <Link onClick={plex} to="#">
                                            <p>
                                                <span>Available</span> <br />
                                                on Play Store
                                            </p>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

    );

}

export default BannerSection;
