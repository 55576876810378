import React from "react";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
// import PlexUltroSection from "../../components/PlexUltroSection";
import PricingSection from "../../components/pricing/PricingSection";
import AppSection2 from "../../components/home/AppSection2";

function PricingPage() {

    return (
        <div>
            <Header />
            <PricingSection />
            {/* <PlexUltroSection/> */}

            <section className="about-area" >
                <div className="container">
                    <AppSection2 />
                </div>
            </section>

            <Footer />
        </div>
    );
}

export default PricingPage
