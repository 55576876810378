import React from "react";
import AppSection from "../../components/home/AppSection";
import BannerSection from "../../components/home/BannerSection";
import FeaturesSection from "../../components/home/FeaturesSection";
import StatisticsSection from "../../components/home/StatisticsSection";
import Footer from "../../shared/Footer";
import Header from "../../shared/Header";
import VideoSection from "../../components/home/VideoSection";
// import AppSection2 from "../../components/home/AppSection2";
import ScreenshotSection from "../../components/home/ScreenshotSection";
import PlexSection from "../../components/home/PlexSection";



class LandingPage extends React.Component {

    render() {

        return (
            <div>
                <Header />
                <BannerSection />
                <StatisticsSection />
                <FeaturesSection />

                <section className="about-area" >
                    <div className="container">
                        <AppSection />
                        {/* <AppSection2 /> */}
                        <PlexSection />
                    </div>
                </section>

                <VideoSection />
                <ScreenshotSection />
                <Footer />
            </div>
        )
    }
}

export default LandingPage;
