import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import LandingPage from '../pages/home/LandingPage';
import PricingPage from '../pages/pricing/PricingPage';
import ContactPage from '../pages/contact/ContactPage';
import AboutPage from '../pages/about/AboutPage';

function Routing() {

    return (
        <Router>
            <Routes>
                <Route path="/" exact element={<LandingPage />} />
                <Route path="/about" element={<AboutPage />} />
                <Route path="/pricing" element={<PricingPage />} />
                <Route path="/contact" element={<ContactPage />} />
            </Routes>
        </Router>

    );
}

export default Routing;
