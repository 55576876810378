
import React from "react";



function StatisticsSection() {

    return (

        <section className="fact-area">
            <div className="container">
                <div className="fact-box">
                    <div className="row align-items-center">
                        <div className="col single-fact">
                            <h2>1K+</h2>
                            <p>Total Downloads</p>
                        </div>
                        <div className="col single-fact">
                            <h2>13+</h2>
                            <p>Positive Reviews</p>
                        </div>
                        <div className="col single-fact">
                            <h2>4K+</h2>
                            <p>Daily Visitors</p>
                        </div>
                        <div className="col single-fact">
                            <h2>3+</h2>
                            <p>App Rating</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );

}

export default StatisticsSection;
