import React from "react";
import frame2 from "../../assets/img/plex/mainScreen.png";

import "react-slideshow-image/dist/styles.css";
import { Link } from "react-router-dom";


// const plex = () => {
//     // window.open("https://plex.multiplexstreetparking.com/");
//     window.open("https://plex-parking.netlify.app");
// }


function PlexSection() {

    return (

        <div className="alt-services row align-items-center section-gap">

            <div className="col-lg-5 d-flex flex-column justify-content-center" style={{ marginLeft: "55px", }}>
                <h1>
                    Your solution to parking
                </h1>

                <div className="icon-box d-flex position-relative">
                    <i className="bx bxs-car flex-shrink-0"></i>
                    <div>
                        <h4>
                            <Link to="#" className="stretched-link point plex-cursor">Find Parking</Link>
                        </h4>
                        <p>
                            You can now personally find where you want to park, view available parking, then go and park
                        </p>
                    </div>
                </div>

                <div className="icon-box d-flex position-relative">
                    <i className="bx bx-link flex-shrink-0"></i>
                    <div>
                        <h4>
                            <Link to="#" className="stretched-link point plex-cursor">Link all your cars</Link>
                        </h4>
                        <p>
                            Do you have a number of cars? If yes, Plex now offers the ability to link all your cars in one app and track each car's parking activity
                        </p>
                    </div>
                </div>

                <div className="icon-box d-flex position-relative">
                    <i className="bx bxs-credit-card flex-shrink-0"></i>
                    <div>
                        <h4>
                            <Link to="#" className="stretched-link point plex-cursor">Virtual Tickets</Link>
                        </h4>
                        <p>
                            Payment is now as easy as a click away, it's now virtual... or even better pay with mobile money
                        </p>
                    </div>
                </div>

                

                {/* <div className="mt-5"  >
                    <div className="box">
                        <div className="btn-wrap">
                            <Link to="#" onClick={plex} className="mt-5 readmore stretched-link">Learn more <i className="bi bi-arrow-right"></i></Link>
                        </div>
                    </div>
                </div> */}

                <div className="download-button d-flex flex-row justify-content-start" style={{ marginTop: "40px" }}>
                    <Link className="primary-btn text-uppercase mt-3" onClick={() => window.scrollTo(0, 0)} to="/about">Get Details</Link>
                </div>

            </div>

            <div className="offset-lg-1 col-lg-5 home-about-left d-flex justify-content-center">

                <img
                    className="img-fluid"
                    style={{
                        width: "350px",
                        margin: "35px 0 35px 0",
                    }}
                    src={frame2}
                    alt=""
                />

            </div>

        </div>

    );

}

export default PlexSection;
