import React from 'react';
import TestimonialSection from '../../components/about/TestimonialSection';
import StatisticsSection from '../../components/home/StatisticsSection';
import PlexUltroSection from '../../components/about/PlexUltroSection';
import Footer from '../../shared/Footer';
import Header from '../../shared/Header';

function AboutPage() {

    return (
        <div>
            <Header />
            <PlexUltroSection/>
            <StatisticsSection />
            {/* <div className='mb-5'></div> */}
            <TestimonialSection />
            <Footer />
        </div>
    );

}

export default AboutPage;
