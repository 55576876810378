
import React from "react";
import { Link } from "react-router-dom";

import play from "../../assets/img/play-btn.png";
import drop from "../../assets/img/plex-drop.png";
// import drop from "../../assets/img/video-bg.jpg";



function VideoSection() {



    return (

        <section className="about-video-area section-gap">
            <div className="vdo-bg">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div 
                            className="col-lg-12 about-video-right justify-content-center align-items-center d-flex relative" 
                            style={{ background: `url(${drop}) no-repeat center` }}
                        >
                            <div className="overlay overlay-bg"></div>
                            <Link className="play-btn" to="https://www.youtube.com/watch?v=ARA0AxrnHdM">
                                <img className="img-fluid mx-auto" src={play}
                                alt="" />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );

}

export default VideoSection;
