import React from "react";
import { Link } from "react-router-dom";
import phoneImg from '../../assets/img/plex/phoneApp.svg';

function PlexUltroSection() {

    const plex = () => {
        window.open("https://play.google.com/store/apps/details?id=com.multiplex.parking");
    }

    return (
        <section className="full-height d-flex justify-content-center align-items-center">
            <div className="container mt-5">
                <div className="row align-items-center">
                    <div className="col-lg-5 home-about-right home-about-right2" >
                        <h1>
                            We believe that Plex parking is your one best parking app
                        </h1>
                        <p>
                            Parking just got better. Plex parking is our new solution to all things parking in Kampala. You can now: <span className="sup">Easily pay</span> for your parking, <span className="sup">Quickly find parking</span> spots, <span className="sup">Monitor your parking activity</span> to avoid clamping and so much more, all at the tap of a button.
                        </p>
                        <div className="download-button d-flex flex-row justify-content-start">
                            <div className="buttons flex-row d-flex">
                                <i className="fa fa-apple" aria-hidden="true"></i>
                                <div className="desc">
                                    <Link onClick={plex} to="#">
                                        <p>
                                            <span>Available</span> <br />
                                            on App Store
                                        </p>
                                    </Link>
                                </div>
                            </div>
                            <div className="buttons dark flex-row d-flex">
                                <i className="fa fa-android" aria-hidden="true"></i>
                                <div className="desc">
                                    <Link onClick={plex} to="#">
                                        <p>
                                            <span>Available</span> <br />
                                            on Play Store
                                        </p>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="offset-lg-1 col-lg-6 home-about-left d-flex justify-content-center">
                        <img 
                            className="img-fluid" 
                            style={{
                                // width: "350px",
                                margin: "35px 0 35px 0",
                            }}
                            src={phoneImg} 
                            alt="" 
                        />
                    </div>
                </div>
            </div>
        </section>
    );

}

export default PlexUltroSection;
