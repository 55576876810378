import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import logo from "../assets/img/plex/logo.png";



function Header() {

    const [hideNav, setHideNav] = useState(true);
    const [activeLocationPath] = useState(window.location.pathname);
    const [activeRoot, setActiveRoot] = useState(true);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        const pathSections = activeLocationPath.split("/");
        setActiveRoot("/" + pathSections[1]);
    }, [activeLocationPath]);

    const handleScroll = () => {
        let scrollPosition = window.pageYOffset;
        if (scrollPosition > 39) {
            setHideNav(false);
        } else {
            setHideNav(true);
        }
    }


    return (

        <header id="header">
            <div className="container main-menu">
                <div className="row align-items-center justify-content-between d-flex">
                    <div id="logo">
                        <Link to="/">
                            {hideNav ? <img src={logo} alt="" title="" style={{ height: "42px" }} /> : <img src={logo} alt="" title="" style={{ height: "42px" }} />}
                        </Link>
                    </div>

                    <nav id="nav-menu-container">
                        <ul className="nav-menu">

                            <li className={`nav-item ${(activeRoot === '/') && 'menu-active'}`}>
                                <Link to="/" onClick={() => window.scrollTo(0, 0)}>Home</Link>
                            </li>
                            <li className={`nav-item ${(activeRoot === '/about') && 'menu-active'}`}>
                                <Link to="/about" onClick={() => window.scrollTo(0, 0)}>About</Link>
                            </li>
                            <li className={`nav-item ${(activeRoot === '/pricing') && 'menu-active'}`}>
                                <Link to="/pricing" onClick={() => window.scrollTo(0, 0)}>Pricing</Link>
                            </li>
                            <li className={`nav-item ${(activeRoot === '/contact') && 'menu-active'}`}>
                                <Link to="/contact" onClick={() => window.scrollTo(0, 0)}>Contact</Link>
                            </li>

                        </ul>
                    </nav>
                </div>
            </div>
        </header>

    );

}

export default Header;
