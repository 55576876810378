
import React from "react";
import SectionHeader from "../../shared/SectionHeader";

import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

import screen1 from "../../assets/img/screenshots/screen_1.png";
import screen2 from "../../assets/img/screenshots/screen_2.png";
import screen3 from "../../assets/img/screenshots/screen_3.png";
import screen4 from "../../assets/img/screenshots/screen_4.png";
import screen5 from "../../assets/img/screenshots/screen_5.png";

import screen6 from "../../assets/img/screenshots/screen_6.png";
import screen7 from "../../assets/img/screenshots/screen_7.png";
import screen8 from "../../assets/img/screenshots/screen_8.png";
import screen9 from "../../assets/img/screenshots/screen_9.png";
import screen10 from "../../assets/img/screenshots/screen_10.png";

import screen105 from "../../assets/img/screenshots/screen_10_5.png";
import screen11 from "../../assets/img/screenshots/screen_11.png";
import screen12 from "../../assets/img/screenshots/screen_12.png";
import screen13 from "../../assets/img/screenshots/screen_13.png";
import screen135 from "../../assets/img/screenshots/screen_13_5.png";

import screen14 from "../../assets/img/screenshots/screen_14.png";
import screen15 from "../../assets/img/screenshots/screen_15.png";


const properties = {
    duration: 7000,
    transitionDuration: 700,
    infinite: true,
    indicators: false,
    arrows: false,
    pauseOnHover: false,
    slidesToScroll: 1,
    slidesToShow: 4
};

const responsiveSettings = [
    {
        breakpoint: 1000,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 4
        }
    },
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 4,
            slidesToScroll: 4
        }
    },
    {
        breakpoint: 600,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3
        }
    },
    {
        breakpoint: 400,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2
        }
    },
    {
        breakpoint: 200,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
];

function ScreenshotSection() {

    return (

        <section className="about-video-area section-gap-top">
        {/* <section className="screenshot-area section-gap border"> */}
            <div className="container">
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-6">
                        <SectionHeader
                            title="Featured Screens"
                            description="Allowing our clients to easily navigate the app is our biggest prized goal. Plex parking app brings you a beautiful easy to use UI as seen below:"
                        />
                    </div>
                </div>

                {/* <img className="img-fluid" src={app1} alt="" /> */}

                <div className="slides-2 swiper">
                    <div className="testimonal-slide-wrapper">
                        <Slide
                            easing="ease"
                            {...properties}
                            responsive={responsiveSettings}
                        >
                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen1} alt="" />
                            </div>
                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen2} alt="" />
                            </div>
                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen3} alt="" />
                            </div>
                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen4} alt="" />
                            </div>
                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen5} alt="" />
                            </div>

                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen6} alt="" />
                            </div>
                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen7} alt="" />
                            </div>
                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen8} alt="" />
                            </div>
                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen9} alt="" />
                            </div>
                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen10} alt="" />
                            </div>

                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen105} alt="" />
                            </div>
                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen11} alt="" />
                            </div>
                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen12} alt="" />
                            </div>
                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen13} alt="" />
                            </div>
                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen135} alt="" />
                            </div>

                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen14} alt="" />
                            </div>
                            <div className="single-screenshot">
                                <img className="img-fluid" src={screen15} alt="" />
                            </div>
                        </Slide>
                    </div>
                </div>
            </div>
        {/* </section> */}
        </section>

    );

}

export default ScreenshotSection;
